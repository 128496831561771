import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Homeen from "./Lang/en/Homeen";
import Homejp from "./Lang/jp/Homejp";

// -------------- 좀비월드 MBTI --------------
import ZombieMain from "./Lang/ko/TestListko/zombieko/ZombieMain";
import ZombieQuestion from "./Lang/ko/TestListko/zombieko/ZombieQuestion";
import ZombieResult from "./Lang/ko/TestListko/zombieko/ZombieResult";

import ZombieMainen from "./Lang/en/TestListen/zombieen/ZombieMainen";
import ZombieQen from "./Lang/en/TestListen/zombieen/ZombieQen";
import ZombieRen from "./Lang/en/TestListen/zombieen/ZombieRen";

import ZombieMainjp from "./Lang/jp/TestListjp/zombiejp/ZombieMainjp";
import ZombieQjp from "./Lang/jp/TestListjp/zombiejp/ZombieQjp";
import ZombieRjp from "./Lang/jp/TestListjp/zombiejp/ZombieRjp";

// -------------- 당신의 분위기 MBTI --------------
import MoodHome from "./Lang/ko/TestListko/yourmoodko/MoodHome";
import MoodQuestion from "./Lang/ko/TestListko/yourmoodko/MoodQuestion";
import MoodResult from "./Lang/ko/TestListko/yourmoodko/MoodResult";

import MoodHomeen from "./Lang/en/TestListen/yourmooden/MoodHomeen";
import MoodQuestionen from "./Lang/en/TestListen/yourmooden/MoodQuestionen";
import MoodResulten from "./Lang/en/TestListen/yourmooden/MoodResulten";

import MoodMainjp from "./Lang/jp/TestListjp/yourmoodjp/MoodMainjp";
import MoodQjp from "./Lang/jp/TestListjp/yourmoodjp/MoodQjp";
import MoodRjp from "./Lang/jp/TestListjp/yourmoodjp/MoodRjp";

// -------------- 롤링페이퍼 --------------
import RollingPaper from "./Lang/ko/TestListko/rollingpaperko/RollingPaper";
import RollingPaperQ from "./Lang/ko/TestListko/rollingpaperko/RollingPaperQ";
import RollingPaperR from "./Lang/ko/TestListko/rollingpaperko/RollingPaperR";
import Rollingpaperen from "./Lang/en/TestListen/rollingpaperen/RollingPaperen";
import RollingpaperQen from "./Lang/en/TestListen/rollingpaperen/RollingPaperQen";
import RollingpaperRen from "./Lang/en/TestListen/rollingpaperen/RollingPaperRen";

import Rollingpaperjp from "./Lang/jp/TestListjp/rollingpaperjp/RollingPaperjp";
import RollingpaperQjp from "./Lang/jp/TestListjp/rollingpaperjp/RollingPaperQjp";
import RollingpaperRjp from "./Lang/jp/TestListjp/rollingpaperjp/RollingPaperRjp";

import RP2Main from "./Lang/ko/TestListko/rollingpaper2ko/RP2Main";
import RP2Q from "./Lang/ko/TestListko/rollingpaper2ko/RP2Q";
import RP2R from "./Lang/ko/TestListko/rollingpaper2ko/RP2R";

import RP2Mainen from "./Lang/en/TestListen/rollingpaper2en/RP2Mainen";
import RP2Qen from "./Lang/en/TestListen/rollingpaper2en/RP2Qen";
import RP2Ren from "./Lang/en/TestListen/rollingpaper2en/RP2Ren";

import RP2Mainjp from "./Lang/jp/TestListjp/rollingpaper2jp/RP2Mainjp";
import RP2Qjp from "./Lang/jp/TestListjp/rollingpaper2jp/RP2Qjp";
import RP2Rjp from "./Lang/jp/TestListjp/rollingpaper2jp/RP2Rjp";

// -------------- 2024 롤링페이퍼 MBTI 우정편 --------------
import RP2024Main from "./Lang/ko/TestListko/rollingpaper2024ko/RP2024Main";
import RP2024Q from "./Lang/ko/TestListko/rollingpaper2024ko/RP2024Q";
import RP2024R from "./Lang/ko/TestListko/rollingpaper2024ko/RP2024R";

// -------------- 2024 롤링페이퍼 MBTI 연애편 --------------
import RP2024loveMain from "./Lang/ko/TestListko/rp2024loveko/RP2024loveMain";
import RP2024loveQ from "./Lang/ko/TestListko/rp2024loveko/RP2024loveQ";
import RP2024loveR from "./Lang/ko/TestListko/rp2024loveko/RP2024loveR";

// -------------- 찐사랑 MBTI--------------
import RealLoveMain from "./Lang/ko/TestListko/realloveko/RealLoveMain";
import RealLoveQ from "./Lang/ko/TestListko/realloveko/RealLoveQ";
import RealLoveR from "./Lang/ko/TestListko/realloveko/RealLoveR";

import RealLoveMainen from "./Lang/en/TestListen/realloveen/RealLoveMainen";
import RealLoveQen from "./Lang/en/TestListen/realloveen/RealLoveQen";
import RealLoveRen from "./Lang/en/TestListen/realloveen/RealLoveRen";

import RealLoveMainjp from "./Lang/jp/TestListjp/reallovejp/RealloveMainjp";
import RealLoveQjp from "./Lang/jp/TestListjp/reallovejp/RealloveQjp";
import RealLoveRjp from "./Lang/jp/TestListjp/reallovejp/RealloveRjp";

// -------------- 플레이리스트 MBTI--------------
import PlaylistMain from "./Lang/ko/TestListko/playlistko/PlaylistMain";
import PlaylistQ from "./Lang/ko/TestListko/playlistko/PlaylistQ";
import PlaylistR from "./Lang/ko/TestListko/playlistko/PlaylistR";

import PlaylistMainen from "./Lang/en/TestListen/playlisten/PlaylistMainen";
import PlaylistQen from "./Lang/en/TestListen/playlisten/PlaylistQen";
import PlaylistRen from "./Lang/en/TestListen/playlisten/PlaylistRen";

// -------------- 키워드 MBTI--------------
import KeywordMbtiMain from "./Lang/ko/TestListko/keywordMbtiko/KeywordMbtiMain";
import KeywordMbtiQ from "./Lang/ko/TestListko/keywordMbtiko/KeywordMbtiQ";
import KeywordMbtiR from "./Lang/ko/TestListko/keywordMbtiko/KeywordMbtiR";

import KeywordMbtiMainen from "./Lang/en/TestListen/keywordMbtien/KeywordMbtiMainen";
import KeywordMbtiQen from "./Lang/en/TestListen/keywordMbtien/KeywordMbtiQen";
import KeywordMbtiRen from "./Lang/en/TestListen/keywordMbtien/KeywordMbtiRen";

import KeywordMbtiMainjp from "./Lang/jp/TestListjp/keywordMbtijp/KeywordMbtiMainjp";
import KeywordMbtiQjp from "./Lang/jp/TestListjp/keywordMbtijp/KeywordMbtiQjp";
import KeywordMbtiRjp from "./Lang/jp/TestListjp/keywordMbtijp/KeywordMbtiRjp";

// -------------- 동물타로 MBTI--------------
import AnimalMain from "./Lang/ko/TestListko/animalMbtiko/AnimalMain";
import AnimalQ from "./Lang/ko/TestListko/animalMbtiko/AnimalQ";
import AnimalR from "./Lang/ko/TestListko/animalMbtiko/AnimalR";

import AnimalMainen from "./Lang/en/TestListen/animalMbtien/AnimalMainen";
import AnimalQen from "./Lang/en/TestListen/animalMbtien/AnimalQen";
import AnimalRen from "./Lang/en/TestListen/animalMbtien/AnimalRen";

import AnimalMainjp from "./Lang/jp/TestListjp/animalMbtijp/AnimalMainjp";
import AnimalQjp from "./Lang/jp/TestListjp/animalMbtijp/AnimalQjp";
import AnimalRjp from "./Lang/jp/TestListjp/animalMbtijp/AnimalRjp";

// -------------- 통모짜 MBTI--------------
import InsomniaMain from "./Lang/ko/TestListko/insomniako/InsomniaMain";
import InsomniaQ from "./Lang/ko/TestListko/insomniako/InsomniaQ";
import InsomniaR from "./Lang/ko/TestListko/insomniako/InsomniaR";

import InsomniaMainen from "./Lang/en/TestListen/insomniaen/InsomniaMainen";
import InsomniaQen from "./Lang/en/TestListen/insomniaen/InsomniaQen";
import InsomniaRen from "./Lang/en/TestListen/insomniaen/InsomniaRen";

// -------------- 검은고양이 타로 --------------
import BlackcatTarotMain from "./Lang/ko/TestListko/blackcatTarotko/BlackcatTarotMain";
import BlackcatTarotR from "./Lang/ko/TestListko/blackcatTarotko/BlackcatTarotR";
import BlackcartTarotCard from "./Lang/ko/TestListko/blackcatTarotko/BlackcartTarotCard";

import BlackcatTarotMainen from "./Lang/en/TestListen/blackcaten/BlackcatTarotMainen";
import BlackcatTarotCarden from "./Lang/en/TestListen/blackcaten/BlackcatTarotCarden";
import BlackcatTarotRen from "./Lang/en/TestListen/blackcaten/BlackcatTarotRen";

import SchoolMain from "./Lang/ko/TestListko/schoolko/SchoolMain";
import SchoolQuestion from "./Lang/ko/TestListko/schoolko/SchoolQuestion";
import SchoolResult from "./Lang/ko/TestListko/schoolko/SchoolResult";

import NojamMain from "./Lang/ko/TestListko/nojamlifeko/NojamMain";
import NojamQuestion from "./Lang/ko/TestListko/nojamlifeko/NojamQuestion";
import NojamResult from "./Lang/ko/TestListko/nojamlifeko/NojamResult";

import AngelMain from "./Lang/ko/TestListko/angelko/AngelMain";
import AngelR from "./Lang/ko/TestListko/angelko/AngelR";
import AlcatineMain from "./Lang/ko/TestListko/alcatineko/AlcatineMain";
import AlcatineQ from "./Lang/ko/TestListko/alcatineko/AlcatineQ";
import AlcatineR from "./Lang/ko/TestListko/alcatineko/AlcatineR";

import FlirtingMain from "./Lang/ko/TestListko/flirtingko/FlirtingMain";
import FlirtingQ from "./Lang/ko/TestListko/flirtingko/FlirtingQ";
import FlirtingR from "./Lang/ko/TestListko/flirtingko/FlirtingR";

import Song2000Main from "./Lang/ko/TestListko/song2000ko/Song2000Main";
import Song2000Q from "./Lang/ko/TestListko/song2000ko/Song2000Q";
import Song2000R from "./Lang/ko/TestListko/song2000ko/Song2000R";

import Pick2024Main from "./Lang/ko/TestListko/pick2024ko/Pick2024Main";
import Pick2024R from "./Lang/ko/TestListko/pick2024ko/Pick2024R";

// -------------- 연애밸런스게임 ------------------
import LoveBalanceMain from "./Lang/ko/TestListko/loveBalanceko/LoveBalanceMain";
import LoveBalanceQ from "./Lang/ko/TestListko/loveBalanceko/LoveBalanceQ";
import LoveBalanceR from "./Lang/ko/TestListko/loveBalanceko/LoveBalanceR";

// -------------- 고양이 애정도 테스트 --------------
import CatlovesMeMain from "./Lang/ko/TestListko/catlovesMe/CatlovesMeMain";
import CatlovesMeQ from "./Lang/ko/TestListko/catlovesMe/CatlovesMeQ";
import CatlovesMeR from "./Lang/ko/TestListko/catlovesMe/CatlovesMeR";

import CatlovesMeMainen from "./Lang/en/TestListen/catlovesMeen/CatlovesMeMainen";
import CatlovesMeQen from "./Lang/en/TestListen/catlovesMeen/CatlovesMeQen";
import CatlovesMeRen from "./Lang/en/TestListen/catlovesMeen/CatlovesMeRen";

import CatlovesMeMainjp from "./Lang/jp/TestListjp/catlovesMejp/CatlovesMeMainjp";
import CatlovesMeQjp from "./Lang/jp/TestListjp/catlovesMejp/CatlovesMeQjp";
import CatlovesMeRjp from "./Lang/jp/TestListjp/catlovesMejp/CatlovesMeRjp";

// -------------- 단어 성격유형 --------------
import WordtypeMain from "./Lang/ko/TestListko/wordtypeko/WordtypeMain";
import WordtypeQ from "./Lang/ko/TestListko/wordtypeko/WordtypeQ";
import WordtypeR from "./Lang/ko/TestListko/wordtypeko/WordtypeR";

import WordtypeMainen from "./Lang/en/TestListen/wordtypeen/WordtypeMainen";
import WordtypeQen from "./Lang/en/TestListen/wordtypeen/WordtypeQen";
import WordtypeRen from "./Lang/en/TestListen/wordtypeen/WordtypeRen";

// -------------- 빅파이브 성격유형 --------------
import BigfiveMain from "./Lang/ko/TestListko/bigfiveko/BigfiveMain";
import BigfiveQ from "./Lang/ko/TestListko/bigfiveko/BigfiveQ";
import BigfiveR from "./Lang/ko/TestListko/bigfiveko/BigfiveR";

import BigfiveMainen from "./Lang/en/TestListen/bigfiveen/BigfiveMainen";
import BigfiveQen from "./Lang/en/TestListen/bigfiveen/BigfiveQen";
import BigfiveRen from "./Lang/en/TestListen/bigfiveen/BigfiveRen";

// -------------- 올림포스 MBTI ------------------
import OlymposMain from "./Lang/ko/TestListko/olymposko/OlymposMain";
import OlymposQ from "./Lang/ko/TestListko/olymposko/OlymposQ";
import OlymposR from "./Lang/ko/TestListko/olymposko/OlymposR";

import OlymposMainen from "./Lang/en/TestListen/olymposen/OlymposMainen";
import OlymposQen from "./Lang/en/TestListen/olymposen/OlymposQen";
import OlymposRen from "./Lang/en/TestListen/olymposen/OlymposRen";

// -------------- 연애사고 테스트 ------------------
import LoveThinkMain from "./Lang/ko/TestListko/lovethinkko/LoveThinkMain";
import LoveThinkQ from "./Lang/ko/TestListko/lovethinkko/LoveThinkQ";
import LoveThinkR from "./Lang/ko/TestListko/lovethinkko/LoveThinkR";

// -------------- 마음속의 집 ------------------
import TheHouseMain from "./Lang/ko/TestListko/theHouseko/TheHouseMain";
import TheHouseQ from "./Lang/ko/TestListko/theHouseko/TheHouseQ";
import TheHouseR from "./Lang/ko/TestListko/theHouseko/TheHouseR";

import ThehouseMainen from "./Lang/en/TestListen/theHouseen/TheHouseMainen";
import TheHouseQen from "./Lang/en/TestListen/theHouseen/TheHouseQen";
import TheHouseRen from "./Lang/en/TestListen/theHouseen/TheHouseRen";

// -------------- 연애 안정성 ------------------
import StabilityMain from "./Lang/ko/TestListko/stabilityko/StabilityMain";
import StabilityQ from "./Lang/ko/TestListko/stabilityko/StabilityQ";
import StabilityR from "./Lang/ko/TestListko/stabilityko/StabilityR";

// -------------- 직업 적성 ------------------
import AptitudeMain from "./Lang/ko/TestListko/aptitudeko/AptitudeMain";
import AptitudeQ from "./Lang/ko/TestListko/aptitudeko/AptitudeQ";
import AptitudeR from "./Lang/ko/TestListko/aptitudeko/AptitudeR";

// -------------- 연애 분위기 MBTI ------------------
import LovemoodMain from "./Lang/ko/TestListko/lovemoodko/LovemoodMain";
import LovemoodQ from "./Lang/ko/TestListko/lovemoodko/LovemoodQ";
import LovemoodR from "./Lang/ko/TestListko/lovemoodko/LovemoodR";

// -------------- 자매 유형 테스트 ------------------
import SistersMain from "./Lang/ko/TestListko/sistersko/SistersMain";
import SistersQ from "./Lang/ko/TestListko/sistersko/SistersQ";
import SistersR from "./Lang/ko/TestListko/sistersko/SistersR";

// -------------- 전통색 찾기 ------------------
import KoreaColorMain from "./Lang/ko/TestListko/koreaColorko/KoreaColorMain";
import KoreaColorQ from "./Lang/ko/TestListko/koreaColorko/KoreaColorQ";
import KoreaColorR from "./Lang/ko/TestListko/koreaColorko/KoreaColorR";

// -------------- 요즘 내 머릿속 ------------------
import InmyheadMain from "./Lang/ko/TestListko/inmyheadko/InmyheadMain";
import InmyheadQ from "./Lang/ko/TestListko/inmyheadko/InmyheadQ";
import InmyheadR from "./Lang/ko/TestListko/inmyheadko/InmyheadR";

// -------------- ㅇㅇ핑 테스트 ------------------
import TestpingMain from "./Lang/ko/TestListko/testpingko/TestpingMain";
import TestpingQ from "./Lang/ko/TestListko/testpingko/TestpingQ";
import TestpingR from "./Lang/ko/TestListko/testpingko/TestpingR";

// -------------- 동물타로 mbti 2탄 ------------------
import Animal2Main from "./Lang/ko/TestListko/animal2ko/Animal2Main";
import Animal2Q from "./Lang/ko/TestListko/animal2ko/Animal2Q";
import Animal2R from "./Lang/ko/TestListko/animal2ko/Animal2R";

// -------------- 코어감정 테스트 ------------------
import CoreMain from "./Lang/ko/TestListko/coreko/CoreMain";
import CoreQ from "./Lang/ko/TestListko/coreko/CoreQ";
import CoreR from "./Lang/ko/TestListko/coreko/CoreR";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/test/en" element={<Homeen />} />
        <Route path="/test/jp" element={<Homejp />} />

        {/* --------- 코어감정 테스트 --------- */}
        <Route path="/test/core" element={<CoreMain />} />
        <Route path="/test/core/question" element={<CoreQ />} />
        <Route path="/test/core/result/:mbti" element={<CoreR />} />

        {/* --------- 동물타로 MBTI 2탄 --------- */}
        <Route path="/test/animal2" element={<Animal2Main />} />
        <Route path="/test/animal2/question" element={<Animal2Q />} />
        <Route path="/test/animal2/result/:mbti" element={<Animal2R />} />

        {/* --------- ㅇㅇ핑 테스트  --------- */}
        <Route path="/test/testping" element={<TestpingMain />} />
        <Route path="/test/testping/question" element={<TestpingQ />} />
        <Route
          path="/test/testping/result/:resultType"
          element={<TestpingR />}
        />

        {/* --------- 요즘 내 머릿속  --------- */}
        <Route path="/test/inmyhead" element={<InmyheadMain />} />
        <Route path="/test/inmyhead/question" element={<InmyheadQ />} />
        <Route path="/test/inmyhead/result/:mbti" element={<InmyheadR />} />

        {/* --------- 전통색 찾기  --------- */}
        <Route path="/test/krcolor" element={<KoreaColorMain />} />
        <Route path="/test/krcolor/question" element={<KoreaColorQ />} />
        <Route path="/test/krcolor/result/:mbti" element={<KoreaColorR />} />

        {/* --------- 자매 유형 테스트  --------- */}
        <Route path="/test/sisters" element={<SistersMain />} />
        <Route path="/test/sisters/question" element={<SistersQ />} />
        <Route path="/test/sisters/result/:type" element={<SistersR />} />

        {/* --------- 연애 분위기 MBTI  --------- */}
        <Route path="/test/lovemood" element={<LovemoodMain />} />
        <Route path="/test/lovemood/question" element={<LovemoodQ />} />
        <Route path="/test/lovemood/result/:mbti" element={<LovemoodR />} />

        {/* --------- 직업 적성  --------- */}
        <Route path="/test/aptitude" element={<AptitudeMain />} />
        <Route path="/test/aptitude/question" element={<AptitudeQ />} />
        <Route path="/test/aptitude/result/:mbti" element={<AptitudeR />} />

        {/* --------- 연애 안정성  --------- */}
        <Route path="/test/stability" element={<StabilityMain />} />
        <Route path="/test/stability/question" element={<StabilityQ />} />
        <Route
          path="/test/stability/result/:resultType/:resultStage"
          element={<StabilityR />}
        />
        {/* --------- 마음속의 집  --------- */}
        <Route path="/test/thehouse" element={<TheHouseMain />} />
        <Route path="/test/thehouse/question" element={<TheHouseQ />} />
        <Route path="/test/thehouse/result/:mbti" element={<TheHouseR />} />

        <Route path="/test/en/thehouseen" element={<ThehouseMainen />} />
        <Route path="/test/en/thehouseen/question" element={<TheHouseQen />} />
        <Route
          path="/test/en/thehouseen/result/:mbti"
          element={<TheHouseRen />}
        />

        {/* --------- 연애사고 테스트  --------- */}
        <Route path="/test/lovethink" element={<LoveThinkMain />} />
        <Route path="/test/lovethink/question" element={<LoveThinkQ />} />
        <Route path="/test/lovethink/result/:mbti" element={<LoveThinkR />} />

        {/* --------- 올림포스 MBTI  --------- */}
        <Route path="/test/olympos" element={<OlymposMain />} />
        <Route path="/test/olympos/question" element={<OlymposQ />} />
        <Route path="/test/olympos/result/:mbti" element={<OlymposR />} />

        <Route path="/test/en/olymposen" element={<OlymposMainen />} />
        <Route path="/test/en/olymposen/question" element={<OlymposQen />} />
        <Route
          path="/test/en/olymposen/result/:mbti"
          element={<OlymposRen />}
        />

        {/* --------- 빅파이브 성격유형  --------- */}
        <Route path="/test/bigfive" element={<BigfiveMain />} />
        <Route path="/test/bigfive/question" element={<BigfiveQ />} />
        <Route path="/test/bigfive/result" element={<BigfiveR />} />

        <Route path="/test/en/bigfiveen" element={<BigfiveMainen />} />
        <Route path="/test/en/bigfiveen/question" element={<BigfiveQen />} />
        <Route path="/test/en/bigfiveen/result" element={<BigfiveRen />} />

        {/* --------- 단어 성격유형  --------- */}
        <Route path="/test/wordtype" element={<WordtypeMain />} />
        <Route path="/test/wordtype/question" element={<WordtypeQ />} />
        <Route path="/test/wordtype/result" element={<WordtypeR />} />

        <Route path="/test/en/wordtypeen" element={<WordtypeMainen />} />
        <Route path="/test/en/wordtypeen/question" element={<WordtypeQen />} />
        <Route path="/test/en/wordtypeen/result" element={<WordtypeRen />} />

        {/* --------- 고양이 애정도 테스트 --------- */}
        <Route path="/test/catlovesme" element={<CatlovesMeMain />} />
        <Route path="/test/catlovesme/question" element={<CatlovesMeQ />} />
        <Route path="/test/catlovesme/result" element={<CatlovesMeR />} />

        <Route path="/test/en/catlovesmeen" element={<CatlovesMeMainen />} />
        <Route
          path="/test/en/catlovesmeen/question"
          element={<CatlovesMeQen />}
        />
        <Route
          path="/test/en/catlovesmeen/result"
          element={<CatlovesMeRen />}
        />

        <Route path="/test/jp/catlovesmejp" element={<CatlovesMeMainjp />} />
        <Route
          path="/test/jp/catlovesmejp/question"
          element={<CatlovesMeQjp />}
        />
        <Route
          path="/test/jp/catlovesmejp/result"
          element={<CatlovesMeRjp />}
        />

        {/* --------- 연애 밸런스 게임 --------- */}
        <Route path="/test/lovebalance" element={<LoveBalanceMain />} />
        <Route path="/test/lovebalance/question" element={<LoveBalanceQ />} />
        <Route path="/test/lovebalance/result" element={<LoveBalanceR />} />

        {/* --------- 검은고양이 타로 --------- */}
        <Route path="/test/blackcattarot" element={<BlackcatTarotMain />} />
        <Route
          path="/test/blackcattarot/select"
          element={<BlackcartTarotCard />}
        />
        <Route path="/test/blackcattarot/result" element={<BlackcatTarotR />} />

        <Route
          path="/test/en/blackcattaroten"
          element={<BlackcatTarotMainen />}
        />
        <Route
          path="/test/en/blackcattaroten/select"
          element={<BlackcatTarotCarden />}
        />
        <Route
          path="/test/en/blackcattaroten/result"
          element={<BlackcatTarotRen />}
        />

        {/* --------- 2024 롤링페이퍼 MBTI 연애편 --------- */}
        <Route path="/test/rollingpaper2024love" element={<RP2024loveMain />} />
        <Route
          path="/test/rollingpaper2024love/question"
          element={<RP2024loveQ />}
        />
        <Route
          path="/test/rollingpaper2024love/result/:mbti"
          element={<RP2024loveR />}
        />

        {/* --------- 2024 롤링페이퍼 MBTI 우정편 --------- */}
        <Route path="/test/rollingpaper2024" element={<RP2024Main />} />
        <Route path="/test/rollingpaper2024/question" element={<RP2024Q />} />
        <Route
          path="/test/rollingpaper2024/result/:mbti"
          element={<RP2024R />}
        />

        {/* --------- 2024 청룡 부적 --------- */}
        <Route path="/test/pick2024" element={<Pick2024Main />} />
        <Route path="/test/pick2024/result" element={<Pick2024R />} />

        {/* --------- 동물타로 MBTI --------- */}
        <Route path="/test/animal" element={<AnimalMain />} />
        <Route path="/test/animal/question" element={<AnimalQ />} />
        <Route path="/test/animal/result/:mbti" element={<AnimalR />} />
        <Route path="/test/en/animalen" element={<AnimalMainen />} />
        <Route path="/test/en/animalen/question" element={<AnimalQen />} />
        <Route path="/test/en/animalen/result/:mbti" element={<AnimalRen />} />
        <Route path="/test/jp/animaljp" element={<AnimalMainjp />} />
        <Route path="/test/jp/animaljp/question" element={<AnimalQjp />} />
        <Route path="/test/jp/animaljp/result/:mbti" element={<AnimalRjp />} />

        {/* --------- 2000년대 노래 감성 테스트 --------- */}
        <Route path="/test/song2000" element={<Song2000Main />} />
        <Route path="/test/song2000/question" element={<Song2000Q />} />
        <Route path="/test/song2000/result" element={<Song2000R />} />

        {/* --------- 플러팅 레벨 테스트  --------- */}
        <Route path="/test/flirting" element={<FlirtingMain />} />
        <Route path="/test/flirting/question" element={<FlirtingQ />} />
        <Route path="/test/flirting/result" element={<FlirtingR />} />

        {/* --------- 키워드 MBTI ---------- */}
        <Route path="/test/keywordMbti" element={<KeywordMbtiMain />} />
        <Route path="/test/keywordMbti/question" element={<KeywordMbtiQ />} />
        <Route
          path="/test/keywordMbti/result/:mbti"
          element={<KeywordMbtiR />}
        />
        <Route path="/test/en/keywordMbtien" element={<KeywordMbtiMainen />} />
        <Route
          path="/test/en/keywordMbtien/question"
          element={<KeywordMbtiQen />}
        />
        <Route
          path="/test/en/keywordMbtien/result/:mbti"
          element={<KeywordMbtiRen />}
        />

        <Route path="/test/jp/keywordMbtijp" element={<KeywordMbtiMainjp />} />
        <Route
          path="/test/jp/keywordMbtijp/question"
          element={<KeywordMbtiQjp />}
        />
        <Route
          path="/test/jp/keywordMbtijp/result/:mbti"
          element={<KeywordMbtiRjp />}
        />

        {/* --------- 찐사랑 MBTI ---------- */}
        <Route path="/test/reallove" element={<RealLoveMain />} />
        <Route path="/test/reallove/question" element={<RealLoveQ />} />
        <Route path="/test/reallove/result/:mbti" element={<RealLoveR />} />
        <Route path="/test/en/realloveen" element={<RealLoveMainen />} />
        <Route path="/test/en/realloveen/question" element={<RealLoveQen />} />
        <Route
          path="/test/en/realloveen/result/:mbti"
          element={<RealLoveRen />}
        />

        <Route path="/test/jp/reallovejp" element={<RealLoveMainjp />} />
        <Route path="/test/jp/reallovejp/question" element={<RealLoveQjp />} />
        <Route
          path="/test/jp/reallovejp/result/:mbti"
          element={<RealLoveRjp />}
        />

        {/* --------- 플레이리스트 MBTI ---------- */}
        <Route path="/test/playlist" element={<PlaylistMain />} />
        <Route path="/test/playlist/question" element={<PlaylistQ />} />
        <Route path="/test/playlist/result/:mbti" element={<PlaylistR />} />

        <Route path="/test/en/playlisten" element={<PlaylistMainen />} />
        <Route path="/test/en/playlisten/question" element={<PlaylistQen />} />
        <Route
          path="/test/en/playlisten/result/:mbti"
          element={<PlaylistRen />}
        />

        {/* --------- 통모짜 불면증 MBTI --------- */}
        <Route path="/test/insomnia" element={<InsomniaMain />} />
        <Route path="/test/insomnia/question" element={<InsomniaQ />} />
        <Route path="/test/insomnia/result/:mbti" element={<InsomniaR />} />

        <Route path="/test/en/insomniaen" element={<InsomniaMainen />} />
        <Route path="/test/en/insomniaen/question" element={<InsomniaQen />} />
        <Route
          path="/test/en/insomniaen/result/:mbti"
          element={<InsomniaRen />}
        />

        {/* 알카틴 테스트 */}
        <Route path="/test/alcatine" element={<AlcatineMain />} />
        <Route path="/test/alcatine/question" element={<AlcatineQ />} />
        <Route path="/test/alcatine/result" element={<AlcatineR />} />
        {/* 수호천사 테스트 */}
        <Route path="/test/angel" element={<AngelMain />} />
        <Route path="/test/angel/result" element={<AngelR />} />
        {/* 도넛 테스트 */}

        {/* --------- 롤링페이퍼 MBTI - 연애편 --------- */}
        <Route path="/test/rollingpaper2" element={<RP2Main />} />
        <Route path="/test/rollingpaper2/question" element={<RP2Q />} />
        <Route path="/test/rollingpaper2/result/:mbti" element={<RP2R />} />

        <Route path="/test/en/rollingpaper2en" element={<RP2Mainen />} />
        <Route path="/test/en/rollingpaper2en/question" element={<RP2Qen />} />
        <Route
          path="/test/en/rollingpaper2en/result/:mbti"
          element={<RP2Ren />}
        />

        <Route path="/test/jp/rollingpaper2jp" element={<RP2Mainjp />} />
        <Route path="/test/jp/rollingpaper2jp/question" element={<RP2Qjp />} />
        <Route
          path="/test/jp/rollingpaper2jp/result/:mbti"
          element={<RP2Rjp />}
        />

        {/* --------- 롤링페이퍼 MBTI  --------- */}
        <Route path="/test/rollingpaper" element={<RollingPaper />} />
        <Route path="/test/rollingpaper/question" element={<RollingPaperQ />} />
        <Route
          path="/test/rollingpaper/result/:mbti"
          element={<RollingPaperR />}
        />
        <Route path="/test/en/rollingpaperen" element={<Rollingpaperen />} />
        <Route
          path="/test/en/rollingpaperen/question"
          element={<RollingpaperQen />}
        />
        <Route
          path="/test/en/rollingpaperen/result/:mbti"
          element={<RollingpaperRen />}
        />
        <Route path="/test/jp/rollingpaperjp" element={<Rollingpaperjp />} />
        <Route
          path="/test/jp/rollingpaperjp/question"
          element={<RollingpaperQjp />}
        />
        <Route
          path="/test/jp/rollingpaperjp/result/:mbti"
          element={<RollingpaperRjp />}
        />

        {/* 인생 노잼력 테스트 */}
        <Route path="/test/nojamlife" element={<NojamMain />} />
        <Route path="/test/nojamlife/question" element={<NojamQuestion />} />
        <Route path="/test/nojamlife/result" element={<NojamResult />} />
        {/* 초딩 MBTI */}
        <Route path="/test/school" element={<SchoolMain />} />
        <Route path="/test/school/question" element={<SchoolQuestion />} />
        <Route path="/test/school/result/:mbti" element={<SchoolResult />} />

        {/* --------- 당신의 분위기 MBTI --------- */}
        <Route path="/test/yourmood" element={<MoodHome />} />
        <Route path="/test/yourmood/question" element={<MoodQuestion />} />
        <Route path="/test/yourmood/result/:mbti" element={<MoodResult />} />

        <Route path="/test/en/yourmooden" element={<MoodHomeen />} />
        <Route
          path="/test/en/yourmooden/question"
          element={<MoodQuestionen />}
        />
        <Route
          path="/test/en/yourmooden/result/:mbti"
          element={<MoodResulten />}
        />

        <Route path="/test/jp/yourmoodjp" element={<MoodMainjp />} />
        <Route path="/test/jp/yourmoodjp/question" element={<MoodQjp />} />
        <Route path="/test/jp/yourmoodjp/result/:mbti" element={<MoodRjp />} />

        {/* --------- 좀비월드 MBTI --------- */}
        <Route path="/test/zombie" element={<ZombieMain />} />
        <Route path="/test/zombie/question" element={<ZombieQuestion />} />
        <Route path="/test/zombie/result/:mbti" element={<ZombieResult />} />

        <Route path="/test/en/zombieen" element={<ZombieMainen />} />
        <Route path="/test/en/zombieen/question" element={<ZombieQen />} />
        <Route path="/test/en/zombieen/result/:mbti" element={<ZombieRen />} />

        <Route path="/test/jp/zombiejp" element={<ZombieMainjp />} />
        <Route path="/test/jp/zombiejp/question" element={<ZombieQjp />} />
        <Route path="/test/jp/zombiejp/result/:mbti" element={<ZombieRjp />} />
      </Routes>
    </Router>
  );
}

export default App;
