import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { useCookies } from "react-cookie";
import "./MomCoupangBanner.css";
import CoupangBannerImg from "./coupangBannerImg.png";

const MomCoupangBanner = ({ handleClick }) => {
  const [showBanner, setShowBanner] = useState(true);
  const [countdown, setCountdown] = useState(5);
  const [showCloseText, setShowCloseText] = useState(false);
  const [cookies, setCookie] = useCookies(["coupang"]);

  const _eventSenderGA = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  useEffect(() => {
    if (countdown === 0) {
      setShowCloseText(true);
      return; // 카운트다운이 0일 때 더 이상 진행하지 않도록 종료
    }

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1); // 이전 카운트다운 값에서 1씩 감소
    }, 1000);

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 해제
  }, [countdown]);

  const handleBannerClose = () => {
    handleClick();
    setShowBanner(false);
    setCookie("coupang", true, {
      path: "/",
      maxAge: 60 * 60 * 2,
      secure: true,
    });
  };

  useEffect(() => {
    if (cookies.coupang) {
      setShowBanner(false);
    }
  }, [cookies]);

  const handleIframeClick = () => {
    window.open("https://link.coupang.com/a/bTIEMg", "_blank");
    setShowBanner(false);
    setCookie("coupang", true, {
      path: "/",
      maxAge: 60 * 60 * 2,
      secure: true,
    });
    _eventSenderGA("Clicking", "Mom_Coupang");
    handleClick();
  };

  return (
    <div className="Mom_coupangBanner_Wrapper">
      {showBanner && (
        <div className="Mom_coupangBanner_ct1">
          <div className="Mom_coupangBanner_coupang_container">
            <p className="Mom_coupangBanner_count">
              {" "}
              {countdown > 0 ? (
                countdown
              ) : showCloseText ? (
                <button
                  className="Mom_coupangBanner_coupang_close_btn"
                  onClick={handleBannerClose}
                >
                  x
                </button>
              ) : (
                ""
              )}
            </p>

            <div className="Mom_coupangBanner_go_to_coupang_container">
              <img
                className="Mom_coupangBanner_go_to_coupang"
                src={CoupangBannerImg}
                alt="CoupangBannerImg"
              />
              <button
                className="Mom_coupangBanner_go_to_coupang2"
                onClick={handleIframeClick}
              ></button>
              <p className="Mom_coupangBanner_p1">지금 쿠팡 방문하고</p>
              <button
                className="Mom_coupangBanner_go_to_coupang_btn"
                onClick={handleIframeClick}
              >
                <p>2시간동안 광고 제거하기</p>
              </button>

              <p className="Mom_coupangBanner_p3">
                - 쿠팡 파트너스 활동의 일환으로 이에 따른 일정액의 수수료를
                제공받습니다 -
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MomCoupangBanner;
