export const QuestionData = [
  {
    id: 1,
    title: "데이트할 때 나는?",
    answera: "대화를 주도하고, 분위기를 이끌어가는 편",
    answerb: "조용히 연인의 이야기를 듣는 편",
    type: "EI",
  },
  {
    id: 2,
    title: "데이트 후에 나는?",
    answera: "친구들한테나 SNS에 사진 올려!",
    answerb: "즐거웠다... (기절)",
    type: "EI",
  },
  {
    id: 3,
    title: "연인과 함께 있을 때 나는?",
    answera: "연인과 함께 보내는 시간으로 \n에너지를 충전해",
    answerb: "연인과 함께하는 것도 좋지만,\n혼자만의 시간도 필요해",
    type: "EI",
  },
  {
    id: 4,
    title: "첫 만남에서 상대의 어떤 점에 끌려?",
    answera: "첫인상에서 바로 느낌이 팍 와",
    answerb: "이야기를 나눠보면 감이 와",
    type: "SN",
  },
  {
    id: 5,
    title: "연인과 이야기를 할 때 나는...",
    answera: "실질적인 데이트 코스를 짜고, \n현실적인 이야기를 하는 게 좋아",
    answerb: "시덥잖은 이야기하면서\n 노는 게 좋아",
    type: "SN",
  },
  {
    id: 6,
    title: `연인이 "만약에~"라면서 \n일어날 리 없는 이야기를 한다면?`,
    answera: "재미없지만 장단 맞춰줌",
    answerb: "나도 과몰입함",
    type: "SN",
  },
  {
    id: 7,
    title: "연인이 고민을 털어놓는다면?",
    answera: "문제를 해결할 방법을 같이 찾아",
    answerb: "감정에 공감하고 위로해주는 편이야",
    type: "TF",
  },
  {
    id: 8,
    title: "연인과 갈등이 생겼을 때 나는?",
    answera: "이성적으로 문제를 바라보고\n타협점을 찾아",
    answerb: "연인의 감정을 먼저 이해하고\n공감하려고 해",
    type: "TF",
  },
  {
    id: 9,
    title: "연애에서 나는?",
    answera: "감성보다 이성",
    answerb: "이성보다 감성",
    type: "TF",
  },
  {
    id: 10,
    title: "데이트 계획이 갑자기 변경되면 나는?",
    answera: "살짝 스트레스지만 어쩔 수 없다고 생각해",
    answerb: "별 생각 안 들어~ 흘러가는 편이야",
    type: "JP",
  },
  {
    id: 11,
    title: "데이트할 때 나는?",
    answera: "뭐 입을지, 어디갈지, 필요한 거 준비 완료!",
    answerb: "이야 날씨 좋다~~~ 가보자고!",
    type: "JP",
  },
  {
    id: 12,
    title: "연인과의 미래 계획을 세울 때 나는?",
    answera: "구체적인 미래 계획을 세우고\n그걸 함께 준비해",
    answerb: "그때그때 상황에 맞춰 자연스럽게\n 진행하는 게 좋아",
    type: "JP",
  },
];
