import React, { useEffect, useState } from "react";
import "./Animal2Q.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { useNavigate } from "react-router-dom";
import { QuestionData } from "./data/QuestionData";
import Take5 from "../../../../components/Take5/Take5";
import backImg from "./img/animal_Q_back.png";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap
  }
  return array;
};

const Animal2Q = () => {
  const [questionNo, setQuestionNo] = useState(0);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [totalScore, setTotalScore] = useState([
    { id: "EI", score: 0 },
    { id: "SN", score: 0 },
    { id: "TF", score: 0 },
    { id: "JP", score: 0 },
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    // 질문을 섞어서 상태에 저장
    setShuffledQuestions(shuffleArray([...QuestionData]));
  }, []);

  const handleClickButton = (no, type) => {
    const newScore = totalScore.map((s) =>
      s.id === type ? { id: s.id, score: s.score + no } : s
    );

    setTotalScore(newScore);

    if (shuffledQuestions.length !== questionNo + 1) {
      // 다음 문제로 넘어감
      setQuestionNo(questionNo + 1);
    } else {
      // mbti 결과 도출
      const mbti = newScore.reduce(
        (acc, curr) =>
          acc +
          (curr.score >= 2 ? curr.id.substring(0, 1) : curr.id.substring(1, 2)),
        ""
      );
      navigate(`/test/animal2/result/${mbti}`);
    }
  };

  return (
    <div className="animal2_Q_Wrapper">
      <Take5 />
      <Progress
        percent={(questionNo / shuffledQuestions.length) * 100}
        status="error"
        theme={{
          error: {
            symbol: "🃏",
            color: "#3a45a94d",
          },
        }}
      />
      <div className="animal2_Q_BackImg">
        <img src={backImg} alt="rp_back" />
      </div>
      {shuffledQuestions.length > 0 && (
        <div className="animal2_Q_Contents">
          <div className="animal2_Q_Title">
            {shuffledQuestions[questionNo].title}
          </div>
          <div className="animal2_Q_Btn_Container">
            <button
              className="animal2_Q_Btn"
              onClick={() =>
                handleClickButton(1, shuffledQuestions[questionNo].type)
              }
              type="button"
            >
              {shuffledQuestions[questionNo].answera}
            </button>
            <button
              className="animal2_Q_Btn"
              onClick={() =>
                handleClickButton(0, shuffledQuestions[questionNo].type)
              }
              type="button"
            >
              {shuffledQuestions[questionNo].answerb}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Animal2Q;
