export const QuestionData = [
  {
    id: 1,
    title: "새로운 도전에 직면했을 때 나는?",
    answera: "기회를 잡고 적극적으로 도전을 받아들여",
    answerb: "조심스럽게 상황을 분석한 뒤 접근해",
    type: "EI",
  },
  {
    id: 2,
    title: "친구가 힘든 이야기를 할 때 나는?",
    answera: "문제를 해결할 방법을 먼저 제안해",
    answerb: "친구의 감정을 공감하고 위로해줘",
    type: "TF",
  },
  {
    id: 3,
    title: "프로젝트를 시작할 때 나는?",
    answera: "구체적인 계획과 현실적인 방법에 집중해",
    answerb: "비전과 큰 그림을 생각하며 시작해",
    type: "SN",
  },
  {
    id: 4,
    title: "의사 결정을 내릴 때 나는?",
    answera: "빠르게 결정을 내리고 추진해",
    answerb: "조금 더 시간을 가지고 신중하게 결정해",
    type: "JP",
  },
  {
    id: 5,
    title: "감정적으로 어려운 상황에서 나는?",
    answera: "내 감정을 억누르고 \n이성적으로 문제를 해결하려 해",
    answerb: "내 감정에 충실하게 상황을 해결하려 해",
    type: "TF",
  },
  {
    id: 6,
    title: "새로운 아이디어를 마주할 때 나는?",
    answera: "아이디어의 실용성과 현실성을 따져봐",
    answerb: "아이디어의 가능성과 창의성이 우선이야",
    type: "SN",
  },
  {
    id: 7,
    title: "친구가 상처를 받았을 때 나는?",
    answera: "친구의 감정을 이해하고 공감하려고 노력해",
    answerb: "어떻게 하면 기분이 나아질지 생각해",
    type: "TF",
  },
  {
    id: 8,
    title: "시간이 많을 때 나는 주로?",
    answera: "미리 계획하고 해야 할 일을 처리해",
    answerb: "그때그때 상황에 따라 자유롭게 행동해",
    type: "JP",
  },
  {
    id: 9,
    title: "중요한 프레젠테이션을 앞두고 나는?",
    answera: "철저하게 준비하고 예상 질문까지 고려해",
    answerb: "기본적인 것만 준비하고 유연하게 대처해",
    type: "JP",
  },
  {
    id: 10,
    title: "새로운 사람을 만날 때 나는?",
    answera: "먼저 말을 걸고 대화를 주도하려 해",
    answerb: "상대방이 먼저 말을 걸 때까지 기다려",
    type: "EI",
  },
  {
    id: 11,
    title: "친구들과의 모임에서 나는?",
    answera: "다양한 사람들과 어울리며 에너지를 충전해",
    answerb: "조용히 몇몇 사람들과 \n깊이 있는 대화를 나누고 싶어",
    type: "EI",
  },
  {
    id: 12,
    title: "누가 울고 있다면?",
    answera: "(이유가 궁금함) '왜 울지?'",
    answerb: "(이유도 궁금함) '왜 울지..?'",
    type: "TF",
  },
];
